import Vue from "vue";
import Vuex, { Store } from "vuex";
import { VIEW_MODE } from '@/utils/constants'

Vue.use(Vuex);

export default new Store({
  state: () => ({
    mode: VIEW_MODE.simple,
    font: 16,
    zoom: 1,
  }),
  getters: {
    getViewMode: state => state.mode,
    getFontSize: state => state.font,
    getZoom: state => state.zoom,
    isNavbarToggle: state => state.font > 18 || state.zoom > 1.3
  },
  mutations: {
    setViewMode(state, mode) {
      state.mode = VIEW_MODE[mode]
    },
    setFontSize(state, size) {
      state.font = size
    },
    setZoom(state, zoomValue) {
      state.zoom = zoomValue
    },
  },
  actions: {
    changeViewMode({ commit }, mode) {
      commit('setViewMode', mode)
    },
    changeFontSize({ commit }, size) {
      commit('setFontSize', size)
    },
    changeZoom({ commit }, zoomValue) {
      commit('setZoom', zoomValue)
    },
  },
  modules: {},
});
