import VueRouter from '@/plugins/router';
import routes from '@/routes';

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        const t = setTimeout(() => {
          // console.log("to.hash", to.hash);
          resolve({
            selector: to.hash,
            // behavior: "smooth",
          });
          clearTimeout(t);
        }, 300);
      });
    } else if (savedPosition) {
      // console.log("savedPosition", savedPosition);
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

export default router;
