import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

// plugins
import '@/plugins/bootstrap-vue';
import '@/plugins/composition-api';
import '@/plugins/prismic'
import '@/firebase'

// font
import '../public/font/stylesheet.css'

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
