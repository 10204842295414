import VueI18n from '@/plugins/i18n';
import ru from './ru';
import uz from './uz';
import en from './en'

export const languages = {
  ru, uz, en
};

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'ru',
  messages: languages,
});

export default i18n;
