import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDDeTMc5gvvFdL5kiaDtVduT-LV8zCaWlk",
  authDomain: "solutionslab-85111.firebaseapp.com",
  projectId: "solutionslab-85111",
  storageBucket: "solutionslab-85111.appspot.com",
  messagingSenderId: "1052970769512",
  appId: "1:1052970769512:web:bf23a9198c8ef7d1e86fdd"
};

export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)

export const storage = getStorage(app)