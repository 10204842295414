export default [
  // {
  //   name: 'Android разработчик',
  //   level: 'middle/senior',
  //   must:
  //     '• Разработка приложений под Android;\n' +
  //     '• Разрабатывать ПО в соответствии с ТЗ;\n' +
  //     '• Исправлять обнаруженные дефекты(баги);\n' +
  //     '• Реализация нестандартных элементов UI.',
  //   requirements:
  //     '• Знание Android SDK (API level 16+);\n' +
  //     '• Знание Java / Kotlin;\n' +
  //     '• Понимание RESTful API и HTTP;\n' +
  //     '• Опыт работы с системами контроля версий (Git);\n' +
  //     '• Знание шаблонов проектирования, принципов SOLID, умение их применять.',
  //   conditions:
  //     '• 9.00 •18.00, 5/2;\n' +
  //     '• Официально трудоустройство;\n' +
  //     '• Зарплата по итогам собеседования•достойная;\n' +
  //     '• Территориально м. Минор.',
  // },
  {
    name: 'Java dasturchisi',
    level: 'Middle/Senior',
    experience: '',
    must: 'Kompaniyaning mahsulotlarini ishlab chiqish va rivojlantirish.',
    requirements:
      '• Java Spring, Spring Boot dasturlarini bilish;\n' +
      '• RESTful API haqida tushunchaga ega bo’lish;\n' +
      '• Relyatsion MBBT (PostgreSQL) bilan ishlash tajribasi;\n' +
      '• Mikroservisli arxitektura tizimlarni ishlab chiqish;\n' +
      '• Yuqori yuklangan ilovalarni ishlab chiqish tajribasi;\n' +
      '• Docker yoki boshqa konteyner yechimlari bilan ishlash tajribasi;\n' +
      '• RabbitMQ yoki Apache Kafka bilan ishlash tajribasi.',
  },
  {
    name: 'IOS dasturchisi',
    level: 'Middle/Senior',
    experience: '',
    must:
      '• Joriy loyihalarni davom ettirish va rivojlantirish;\n' +
      '• Kompaniyaning yangi loyihalarini ishlab chiqish.',
    requirements:
      '• Swift-da dasturlash;\n' +
      '• TVga muvofiq dasturiy ta\'minotni ishlab chiqish;\n' +
      '• Aniqlangan nuqsonlarni (buglarni) tuzatish;\n' +
      '• Nostandart UI elementlarini amalga oshirish.',
  },
  {
    name: 'PHP dasturchisi',
    level: 'Middle/Senior',
    experience: '',
    must:
      '• PHP + MySQL/PostgreSQL da veb-ilovalarni ishlab chiqish;\n' +
      '• Servis mantiqini amalga oshirish;\n' +
      '• Sayt funksionalligini yaratish;\n' +
      '• API yaratish.',
    requirements:
      '• Laravel(PHP) framework bilan ishlash tajribasi;\n' +
      '• REST API yozish, uchinchi tomon API’lari bilan ishlash tajribasi;\n' +
      '• SQL so\'rovlarini yozish va MySQL, PostgreSQL, Redis bilan ishlash qobiliyati;\n' +
      '• OOP, SOLID tamoyillarini bilish;\n' +
      '• Git bilan ishlash tajribasi.',
  },
  {
    name: 'Frontend dasturchisi',
    level: 'Middle/Senior',
    experience: '',
    must:
      '• Vue.js-da Frontend ilovasini ishlab chiqish;\n' +
      '• Mavjud mahsulotlarni qo\'llab-quvvatlash va takomillashtirish;\n' +
      '• Asinxron aloqani bilish (promises, async/await);\n' +
      '• JavaScript/TypeScript modulliligini bilish;\n' +
      '• Brauzerlar-aro tartib (verstka) ko‘nikmalariga ega bo‘lish, renderlash qanday ishlashini tushunish;\n' +
      '• HTML va CSS (Less, Sass) bo\'yicha kuchli bilim.',
    requirements:
      '• Vue.js, HTML/CSS, JavaScript-ni bilish;\n' +
      '• Git va Github/Gitlab bilan ishlashni bilish;\n' +
      '• REST API loyihalashtirish tamoyillarini tushunish.',
  },
];