import vacancies from './vacancies';
import slugify from 'slugify';
import { SALARY } from '../../utils/constants';

export default [
  {
    title: 'Jamiyat hujjatlari',
    text: `
      <a href="/docs/ustav.pdf" target="_blank">Nizom</a><br>
      <a href="/docs/Уста%20АО%20SL%2022052023%20адрес%20май.pdf" target="_blank">AS SOLUTIONS LAB nizomi yangi nashrda” 2023 yil may</a> <br>
      <a href="/docs/устав%20АО%20SL%2020022023.pdf" target="_blank">Nizom 20-02-2023</a><br>
      <a href="/docs/гувохнома%20АО%20SL.PDF" target="_blank">Davlat ro'yxatidan o'tkazilganligi to'g'risidagi guvohnoma</a> <br>
      <a href="/docs/Положение%20Исп%20орган%20АО.PDF" target="_blank">Ijro etuvchi organ to'g'risidagi nizom</a><br>
      <a href="/docs/Положение%20о%20НС%20АО.PDF" target="_blank">Kuzatuv kengashi to'g'risidagi nizom</a><br>
      <a href="/docs/Положение%20ОСА%20АО%20SL.PDF" target="_blank">Aksiyadorlarning umumiy yig'ilishi to'g'risidagi nizom</a><br>
      <a href="/docs/Кодекс_корпоратвиного_управления_АО.PDF" target="_blank">AJ Korporativ boshqaruv kodeksi</a><br>
      <a href="/docs/Регламент%20провдедения%20ВОСА.PDF" target="_blank">Navbatdan tashqari umumiy yig'ilishni o'tkazish qoidalari</a> <br>
      <a href="/docs/гувохнома%20АО%20SL.PDF" target="_blank">AJ SOLUTIONS LAB ro'yxatdan o'tish guvohnomasi 24-05-2023</a> <br>
      <a href="/docs/Устав%20SL%2030_06_2023%20ГОСА.pdf" target="_blank">30-06-2023 yildagi umumiy yig'ilish natijalari bo'yicha "SOLUTIONS LAB" AJ Nizomi</a>
    `,
  },
  {
    title: 'Hisobotlar',
    text: `
      <a href="/docs/ГОДОВОЙ_ОТЧЕТ_эмитента_по_итогам_2022_НСБУ.pdf" target="_blank">2023 yilgi AGM natijalari bo'yicha UFRS emitentining yillik hisoboti</a> <br>
      <a href="/docs/ГОДОВОЙ_ОТЧЕТ_эмитента_по_итогам_2022_МСФО.pdf" target="_blank">30-06-2023 yilgi umumiy yig'ilish natijalari bo'yicha NAS emitentining yillik hisoboti</a> <br>
      <h4>Hisobotlar - 2023</h4>
      <h6>Har choraklik hisobot</h6>
      <a href="/docs/квартальный_отчет_по_итогам_1_кварт_АО_Solutions_Lab.pdf" target="_blank">Yuklab olish</a> <br>
      <a href="/docs/кварт%20отчет%20по%20итогам%206%20мес%20АО%20SL.PDF" target="_blank">2-chorak (yarim yillik) yakunlari boʻyicha choraklik hisobot” 28.07.2023</a> <br>
      <a href="/docs/Объявление_о_преимущественном_праве.pdf" target="_blank">Imtiyozli huquqni e'lon qilish</a>
    `,
  },
  {
    title: 'Moddiy faktlar haqida ma\'lumot',
    text: `
      <a href="/docs/facts.pdf" target="_blank">Moddiy fakt № 25 (16.11.2022)</a> <br>
      <a href="/docs/информация_о_покупке_20_процентов_акций.pdf" target="_blank">Aktsiyalarning 20 foizini sotib olish to'g'risida ma'lumot</a> <br>
      <a href="/docs/информация_о_покупке_35_процентов_акций.pdf" target="_blank">Aktsiyalarning 35 foizini sotib olish to'g'risida ma'lumot</a> <br><br>
      <h4>MODDIY FAKTLAR 2023 YIL</h4>
      <a href="/docs/существенный%20факт%2035.pdf" target="_blank">Moddiy fakt № 35</a> <br>
      <a href="/docs/существенный%20факт%2036.pdf" target="_blank">Moddiy fakt № 36</a> <br>
      <a href="/docs/итоги%20собрания%20ВОСА-20-02-2023.pdf" target="_blank">ANTUY majlisi natijalari</a><br>
      <a href="/docs/сущ%20факт%2036%20афф%20лица%20TFT%20вхождение.pdf" target="_blank">30.03.2023 yildagi 36-moddiy fakt</a> <br>
      <a href="/docs/сущ%20факт%2002%20адрес.pdf" target="_blank">Moddiy fakt 02</a> <br>
      <a href="/docs/сущ%20факт%2006%20по%20итогам%202022.pdf" target="_blank">Yig'ilish natijalari bo'yicha 06-moddiy fakt</a> <br>
      <a href="/docs/сущ%20факт%2032%20по%20итогам%202022.pdf" target="_blank">Yig'ilish natijalari bo'yicha 32-moddiy fakt</a> <br>
      <a href="/docs/сущ%20факт%2006%20выпуск%20акций.pdf" target="_blank">Moddiy fakt 06 aktsiyalar chiqarish</a> <br>
      <a href="/docs/сущ%20факт%2006%20по%20итогам%20ВОСА%2014082023.pdf" target="_blank">Yig'ilish natijalari bo'yicha 06-moddiy fakt 14-08-2023</a> <br>
      <a href="/docs/сущ%20факт%2008%20изменение%20НС%2014082023.pdf" target="_blank">Moddiy fakt 06 o'zgarish НС 14-08-2023</a> <br>
      <a href="/docs/сущ_факт_36_афф_лица_наб_совет_смена_14082023.pdf" target="_blank">Moddiy fakt 36 14-08-2023</a> <br>
      <a href="/docs/сущ%20факт%2006%20выпуск%20акций%2025082023.pdf" target="_blank">Moddiy fakt 06 25.08.2023</a> <br>
      <a href="/docs/сущ%20факт%2025%20от%2021.09.2023%20выпуск%20акций.pdf" target="_blank">Moddiy fakt 25 21.09.2023</a>
    `,
  },
  {
    title: 'Ro\'yxat',
    text: `
      <a href="/docs/сущ%20факт%20листинг%2024112022%20пдф.pdf" target="_blank">Muhim ro'yxatga olish fakti</a> <br>
      <a href="/docs/ПРЕДЛОЖЕНИЕ_О_ПРОДАЖЕ_АКЦИЙ_Солюшн_Лаб.pdf" target="_blank">AJ "Solutions Lab" AKSIYALARNI SOTISH TAKLIFI</a>
    `,
  },
  {
    title: 'Kompaniya tomonidan o\'tkaziladigan ochiq tanlovlar (tenderlar) va auktsionlar to\'g\'risidagi ma\'lumotlar',
    text: '',
  },
  {
    title: 'Kompaniyaning moliyaviy-iqtisodiy holatining asosiy ko\'rsatkichlari bo\'yicha dolzarb ma\'lumotlar',
    text: '',
  },
  {
    title: 'Affillangan shaxslar haqida ma\'lumot',
    text: `
      <a href="/docs/Перечень_аффилированных_лиц_16012023.pdf" target="_blank">Affillangan shaxslar ro'yxati 16.01.2023</a> <br>
      <a href="/docs/Перечень_аффилированных_лиц_17102022.pdf" target="_blank">Affillangan shaxslar ro'yxati 17.10.2022</a>
    `,
  },
  {
    title: 'Boshqaruv tuzilishi',
    text: `
      <a href="/docs/структура%20управления%20АО%20SOLUTIONS%20LAB.PDF" target="_blank">Boshqaruv tuzilishi АО SOLUTIONS LAB</a>
    `,
  },
  {
    title: 'Dividendlar haqida ma\'lumot',
    text: '',
  },
  {
    title: 'Rahbarlar haqida ma\'lumot',
    text: `
      <a href="/docs/Информация%20о%20руководителях%20в%20АО.pdf" target="_blank">OAJ dagi rahbarlar haqida ma'lumot</a>
    `,
  },
  {
    title: 'Biznes rejasi',
    text: '',
  },
  {
    title: 'Auditorlik hisobotlari',
    text: `
      <a href="/docs/Аудит_Соллюшен_2022г%20МСФО.pdf" target="_blank">2022 yil uchun UFRS audit hisoboti</a>
    `,
  },
  {
    title: 'Ish o’rinlari',
    text: vacancies.map((vacancy, index) => {
      return `
        <a href="/vacancies/${slugify(Object.keys(SALARY)[index])}">${vacancy.name}</a> <br>
      `;
    }).join(''),
  },
  {
    title: 'Aksiyadorlarning umumiy yig\'ilishi',
    text: `
      <a href="/docs/Объявление_о_проведении_Внеочередного_общего_собрания_акционеров.pdf" target="_blank">Aksiyadorlarning navbatdan tashqari_umumiy_yig'ilishi to'g'risidagi_e'lon</a> <br>
      <a href="/docs/Объявление_о_проведении_Внеочередного_общего_собрания_акционеров%2031-01-2023.pdf" target="_blank">Aksiyadorlarning navbatdan tashqari_umumiy_yig'ilishi to'g'risidagi_e'lon 31-01-2023</a> <br>
      <a href="/docs/Уведомление%20Акционерам%2012.12.2022.pdf" target="_blank">Aktsiyadorlar uchun xabarnoma 12.12.2022</a> <br>
      <a href="/docs/Уведомление%20Акционерам%2030.01.2023.pdf" target="_blank">Aktsiyadorlar uchun xabarnoma 31-01-2023</a> <br>
      <a href="/docs/уведомление%20о%20переносе%20воса.pdf" target="_blank">Navbatdan tashqari umumiy yig'ilishni ko'chirish to'g'risida xabarnoma</a> <br>
      <a href="/docs/уведомление%20о%20переносе%20воса%2020-02-2023.pdf" target="_blank">Navbatdan tashqari umumiy yig'ilishni 20-02-2023 yilga ko'chirish to'g'risida xabarnoma</a> <br>
      <a href="/docs/Отчет_по_итогам_голосования_ВОСА_20022023.PDF" target="_blank">Aksiyadorlar ning navbatdan tashqari umumiy yig'ilishida ovoz berish natijalari to'g'risidagi hisobot 20-02-2023</a> <br>
      <a href="/docs/протокол%20ВОСА%2020022023.PDF" target="_blank">Aksiyadorlarning navbatdan tashqari umumiy yig'ilishi bayoni 20-02-2023</a> <br>
      <a href="/docs/Уведомление_Акционерам_ВОСА_22052023.pdf" target="_blank">Aksiyadorlarning navbatdan tashqari yig'ilishi to'g'risidagi xabar 22-05-2023</a> <br>
      <a href="/docs/Протокол%20ВОСА%20АО%20SL%2022052023%20адрес.PDF" target="_blank">Aksiyadorlarning navbatdan tashqari umumiy yig'ilishi bayoni 20-05-2023</a> <br>
      <a href="/docs/отчет_об_итогах_голосования_22052023_адрес.PDF" target="_blank">Ovoz berish natijalari hisoboti 22-05-2023</a> <br>
      <a href="/docs/Уведомление_Акционерам_ГОСА_по_итогам_2022.pdf" target="_blank">2022 yil yakunlari bo'yicha yillik navbatdagi umumiy yig'ilishni o'tkazish to'g'risida aksiyadorlarga xabardornoma</a> <br>
      <a href="/docs/Отчет_об_итогах_голосования_ГОСА_30_06_2023.PDF" target="_blank">Ovoz berish natijalari hisoboti 30-06-2023</a><br>
      <a href="/docs/уведомление%2014%2008%202023%20НС.pdf" target="_blank">14-08-2023 yilda umumiy yig'ilishni o'tkazish to'g'risida aksiyadorlarga xabardornoma</a> <br>
      <a href="/docs/ВЫПИСКА%20С%20ПРОТОКОЛА%2014082023.PDF" target="_blank">PROTOKOLDAN KO'CHIRMA 14-08-2023</a> <br>
      <a href="/docs/итоги%20собрания%20ВОСА-14_08_2023.pdf" target="_blank">Aksiyadorlarning navbatdan tashqari umumiy yig'ilishi natijalari 14.08.2023</a> <br>
      <a href="/docs/Отчет%20об%20итогах%20голосоваия%2014082023.PDF" target="_blank">Aksiyadorlarning navbatdan tashqari umumiy yig'ilishi bayoni 14.08.2023</a> <br>
      <a href="/docs/Протокол_об_итогах_голосования_ВОСА_14082023.PDF" target="_blank">Aksiyadorlarning navbatdan tashqari umumiy yig'ilishi protokoli 14.08.2023</a>
    `,
  },
  {
    title: 'Saytga tashrif buyurish statistikasi',
    text: '',
  },
  {
    title: 'Emissiya prospektlari',
    text: `<!--<a href="/docs/akcii.PDF" target="_blank">АО SOLUTIONS LAB aksiyalarini chiqarish to'g'risidagi qaror</a>-->`,
  },
  {
    title: 'Xavfsizlik',
    text: '',
  },
];