export default [
  // {
  //   name: 'Android разработчик',
  //   level: 'middle/senior',
  //   must:
  //     '• Разработка приложений под Android;\n' +
  //     '• Разрабатывать ПО в соответствии с ТЗ;\n' +
  //     '• Исправлять обнаруженные дефекты(баги);\n' +
  //     '• Реализация нестандартных элементов UI.',
  //   requirements:
  //     '• Знание Android SDK (API level 16+);\n' +
  //     '• Знание Java / Kotlin;\n' +
  //     '• Понимание RESTful API и HTTP;\n' +
  //     '• Опыт работы с системами контроля версий (Git);\n' +
  //     '• Знание шаблонов проектирования, принципов SOLID, умение их применять.',
  //   conditions:
  //     '• 9.00 •18.00, 5/2;\n' +
  //     '• Официально трудоустройство;\n' +
  //     '• Зарплата по итогам собеседования•достойная;\n' +
  //     '• Территориально м. Минор.',
  // },
  {
    name: 'Java developer',
    level: 'Middle/Senior',
    experience: '',
    must: 'Development and improvement of company products.',
    requirements:
      '• Knowledge of Java Spring, Spring Boot;\n' +
      '• Understanding RESTfull API;\n' +
      '• Experience with relational DBMS (PostgreSQL);\n' +
      '• Development of systems with microservice architecture;\n' +
      '• Experience in developing high-load applications;\n' +
      '• Experience with Docker or other container solutions;\n' +
      '• Experience with RabbitMQ or Apache Kafka.',
  },
  {
    name: 'IOS developer',
    level: 'Middle/Senior',
    experience: '',
    must:
      '• Maintenance and improvement of current projects;\n' +
      '• Development of new Flutter based (Android/iOS) projects of the company.',
    requirements:
      '• Swift development;\n' +
      '• Development of software in accordance with the TSD;\n' +
      '• Fixing detected defects (bugs);\n' +
      '• Implementation of non-standard UI elements.',
  },
  {
    name: 'PHP developer',
    level: 'Middle/Senior',
    experience: '',
    must:
      '• Development of web applications in PHP + MySQL / PostgreSQL; \n' +
      '• Implementation of service logic; \n' +
      '• Building site functionality; \n' +
      '• API development.',
    requirements:
      '• Experience with Laravel (PHP) framework;\n' +
      '• Experience writing REST API, interactions with third-party APIs;\n' +
      '• Ability to write SQL queries and work with MySQL, PostgreSQL, Redis.\n' +
      '• Knowledge of OOP, SOLID principles;\n' +
      '• Experience with Git.',
  },
  {
    name: 'Frontend developer',
    level: 'Middle/Senior',
    experience: '',
    must:
      '• Development of Frontend applications on Vue.js; \n' +
      '• Support and improvement of existing products; \n' +
      '• Knowledge of asynchronous interaction (promises, async/await); \n' +
      '• Knowledge of JavaScript/TypeScript modularity; \n' +
      '• Having cross-browser layout skills, understanding how rendering works; \n' +
      '• Strong knowledge of HTML and CSS (less, sass).\n',
    requirements:
      '• Knowledge of Vue.js, HTML/CSS, JavaScript;\n' +
      '• Ability to work with Git and Github/Gitlab;\n' +
      '• Understanding of REST API design principles.',
  },
];
