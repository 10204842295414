export default [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/index'),
    meta: {
      isNavLink: false,
    },
  },
  {
    path: '/certificates',
    name: 'certificates',
    component: () => import('@/pages/certificates'),
    meta: {
      isNavLink: false,
    }
  },
  {
    path: '/vacancies',
    name: 'vacancies',
    component: () => import('../pages/vacancies'),
    meta: {
      isNavLink: true,
    },
  },
  {
    path: '/vacancies/:id',
    name: 'vacancy',
    component: () => import('../pages/vacancy'),
    props: true,
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../pages/contact'),
  },
  {
    path: '/corporate-management',
    name: 'corporate-management',
    component: () => import('../pages/corporate-management'),
    meta: {
      isNavLink: true,
    },
  },
];