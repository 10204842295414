import vacancies from './vacancies';
import slugify from 'slugify';
import { SALARY } from '../../utils/constants';

export default [
  {
    title: 'Документы общества',
    text: `
      <a href="/docs/ustav.pdf" target="_blank">Устав</a> <br>
      <a href="/docs/Уста%20АО%20SL%2022052023%20адрес%20май.pdf" target="_blank">Устав АО SOLUTIONS LAB в новой редакции" май 2023</a> <br>
      <a href="/docs/устав%20АО%20SL%2020022023.pdf" target="_blank">Устав 20-02-2023</a><br>
      <a href="/docs/гувохнома%20АО%20SL.PDF" target="_blank">Свидетельство о государственной регистрации</a> <br>
      <a href="/docs/Положение%20Исп%20орган%20АО.PDF" target="_blank">Положение Исп орган АО</a><br>
      <a href="/docs/Положение%20о%20НС%20АО.PDF" target="_blank">Положение о НС АО</a><br>
      <a href="/docs/Положение%20ОСА%20АО%20SL.PDF" target="_blank">Положение ОСА АО SL</a><br>
      <a href="/docs/Кодекс_корпоратвиного_управления_АО.PDF" target="_blank">Кодекс корпоратвиного управления АО</a><br>
      <a href="/docs/Регламент%20провдедения%20ВОСА.PDF" target="_blank">Регламент провдедения ВОСА</a> <br>
      <a href="/docs/гувохнома%20АО%20SL%2022052023%20адрес.PDF" target="_blank">Свидетельство о регистрации АО SOLUTIONS LAB 24-05-2023</a> <br>
      <a href="/docs/Устав%20SL%2030_06_2023%20ГОСА.pdf" target="_blank">Устав АО SOLUTIONS LAB по итогам ГОСА 30-06-2023</a>
    `,
  },
  {
    title: 'Отчеты',
    text: `
      <a href="/docs/ГОДОВОЙ_ОТЧЕТ_эмитента_по_итогам_2022_НСБУ.pdf" target="_blank">Годовой отчет эмитента НСБУ  по итогам ГОСА 30-06-2023</a> <br>
      <a href="/docs/ГОДОВОЙ_ОТЧЕТ_эмитента_по_итогам_2022_МСФО.pdf" target="_blank">Годовой отчет эмитента МСФО по итогам ГОСА 2023</a> <br>
      <h4>Отчеты - 2023</h4>
      <h6>Квартальный отчет</h6>
      <a href="/docs/квартальный_отчет_по_итогам_1_кварт_АО_Solutions_Lab.pdf" target="_blank">Скачать</a><br>
      <a href="/docs/кварт%20отчет%20по%20итогам%206%20мес%20АО%20SL.PDF" target="_blank">Квартальный отчет по итогам 2 квартала (полугодие)" 28.07.2023</a> <br>
      <a href="/docs/Объявление_о_преимущественном_праве.pdf" target="_blank">Объявление о преимущественном праве</a>
    `,
  },
  {
    title: 'Информация о существенных фактах',
    text: `
      <a href="/docs/facts.pdf" target="_blank">Существенный факт № 25 (16.11.2022)</a> <br>
      <a href="/docs/информация_о_покупке_20_процентов_акций.pdf" target="_blank">Информация о покупке 20 процентов акций</a> <br>
      <a href="/docs/информация_о_покупке_35_процентов_акций.pdf" target="_blank">Информация о покупке 35 процентов акций</a> <br><br>
      <h4>СУЩЕСТВЕННЫЕ ФАКТЫ 2023 ГОД</h4>
      <a href="/docs/существенный%20факт%2035.pdf" target="_blank">Существенный факт № 35</a> <br>
      <a href="/docs/существенный%20факт%2036.pdf" target="_blank">Существенный факт № 36</a> <br>
      <a href="/docs/итоги%20собрания%20ВОСА-20-02-2023.pdf" target="_blank">Итоги собрания ВОСА 20-02-2023</a> <br>
      <a href="/docs/сущ%20факт%2036%20афф%20лица%20TFT%20вхождение.pdf" target="_blank">Существенный факт 36 от 30-03-2023</a> <br>
      <a href="/docs/сущ%20факт%2002%20адрес.pdf" target="_blank">Существенный факт 02</a> <br>
      <a href="/docs/сущ%20факт%2006%20по%20итогам%202022.pdf" target="_blank">Существенный факт 06 по итогам ГОСА</a> <br>
      <a href="/docs/сущ%20факт%2032%20по%20итогам%202022.pdf" target="_blank">Существенный факт 32 по итогам ГОСА</a> <br>
      <a href="/docs/сущ%20факт%2006%20выпуск%20акций.pdf" target="_blank">Существенный факт 06 выпуск акции</a> <br>
      <a href="/docs/сущ%20факт%2006%20по%20итогам%20ВОСА%2014082023.pdf" target="_blank">Существенный факт 06 по итогам ГОСА 14-08-2023</a> <br>
      <a href="/docs/сущ%20факт%2008%20изменение%20НС%2014082023.pdf" target="_blank">Существенный факт 06 изменения НС 14-08-2023</a> <br>
      <a href="/docs/сущ_факт_36_афф_лица_наб_совет_смена_14082023.pdf" target="_blank">сущ_факт_36_афф_лица_наб_совет_смена_14-08-2023</a> <br>
      <a href="/docs/сущ%20факт%2006%20выпуск%20акций%2025082023.pdf" target="_blank">существенный факт 06 от 25.08.2023</a> <br>
      <a href="/docs/сущ%20факт%2025%20от%2021.09.2023%20выпуск%20акций.pdf" target="_blank">Существенный факт 25 от 21.09.2023</a>
    `,
  },
  {
    title: 'Листинг',
    text: `
      <a href="/docs/сущ%20факт%20листинг%2024112022%20пдф.pdf" target="_blank">Существенный факт о включении в листинг 24.11.2022</a> <br>
      <a href="/docs/ПРЕДЛОЖЕНИЕ_О_ПРОДАЖЕ_АКЦИЙ_Солюшн_Лаб.pdf" target="_blank">ПРЕДЛОЖЕНИЕ О ПРОДАЖЕ АКЦИЙ АО "Solutions Lab"</a>
    `,
  },
  {
    title: 'Сведения об открытых конкурсах (тендерах) и аукционах, проводимыми обществом;',
    text: '',
  },
  {
    title: 'Актуальные сведения об основных показателях финансово-хозяйственного состояния обществом',
    text: '',
  },
  {
    title: 'Информация об аффилированных лицах',
    text: `
      <a href="/docs/Перечень_аффилированных_лиц_16012023.pdf" target="_blank">Перечень аффилированных лиц 16.01.2023</a> <br>
      <a href="/docs/Перечень_аффилированных_лиц_17102022.pdf" target="_blank">Перечень аффилированных лиц 17.10.2022</a>
    `,
  },
  {
    title: 'Структура управления',
    text: `
      <a href="/docs/структура%20управления%20АО%20SOLUTIONS%20LAB.PDF" target="_blank">Структура управления АО SOLUTIONS LAB</a>
    `,
  },
  {
    title: 'Информация о дивидендах',
    text: '',
  },
  {
    title: 'Информация о руководителях',
    text: `
      <a href="/docs/Информация%20о%20руководителях%20в%20АО.pdf" target="_blank">Информация о руководителях в АО</a>
    `,
  },
  {
    title: 'Бизнес план',
    text: '',
  },
  {
    title: 'Аудиторские заключения',
    text: `
      <a href="/docs/Аудит_Соллюшен_2022г%20МСФО.pdf" target="_blank">Аудиторское заключение МСФО по итогам 2022 года</a>
    `,
  },
  {
    title: 'Вакансии',
    text: vacancies.map((vacancy, index) => {
      return `
        <a href="/vacancies/${slugify(Object.keys(SALARY)[index])}">${vacancy.name}</a> <br>
      `;
    }).join(''),
  },
  {
    title: 'Общее собрание акционеров',
    text: `
      <a href="/docs/Объявление_о_проведении_Внеочередного_общего_собрания_акционеров.pdf" target="_blank">Объявление о проведении внеочередного общего собрания акционеров</a> <br>
      <a href="/docs/Объявление_о_проведении_Внеочередного_общего_собрания_акционеров%2031-01-2023.pdf" target="_blank">Объявление о проведении внеочередного общего собрания акционеров 31-01-2023</a> <br>
      <a href="/docs/Уведомление%20Акционерам%2012.12.2022.pdf" target="_blank">Уведомление акционерам</a> <br>
      <a href="/docs/Уведомление%20Акционерам%2030.01.2023.pdf" target="_blank">Уведомление акционерам 31-01-2023</a> <br>
      <a href="/docs/уведомление%20о%20переносе%20воса.pdf" target="_blank">Уведомление о переносе внеочередное общее собрание</a> <br>
      <a href="/docs/уведомление%20о%20переносе%20воса%2020-02-2023.pdf" target="_blank">Уведомление о переносе внеочередное общее собрание на 20-02-2023</a> <br>
      <a href="/docs/Отчет_по_итогам_голосования_ВОСА_20022023.PDF" target="_blank">Отчет по итогам голосования внеочередного общего собрания акционеров 20-02-2023</a> <br>
      <a href="/docs/протокол%20ВОСА%2020022023.PDF" target="_blank">Протокол внеочередного общего собрания акционеров 20-02-2023</a> <br>
      <a href="/docs/Уведомление_Акционерам_ВОСА_22052023.pdf" target="_blank">Уведомление о внеочередном собрании акционеров 22-05-2023</a> <br>
      <a href="/docs/Протокол%20ВОСА%20АО%20SL%2022052023%20адрес.PDF" target="_blank">Протокол внеочередного общего собрания акционеров 20-05-2023</a> <br>
      <a href="/docs/отчет_об_итогах_голосования_22052023_адрес.PDF" target="_blank">Отчет об итогах голосования 22-05-2023</a> <br>
      <a href="/docs/Уведомление_Акционерам_ГОСА_по_итогам_2022.pdf" target="_blank">Уведомление акционерам о проведении ГОСА по итогам 2022 года</a> <br>
      <a href="/docs/Отчет_об_итогах_голосования_ГОСА_30_06_2023.PDF" target="_blank">Отчет об итогах голосования 30-06-2023</a> <br>
      <a href="/docs/ВЫПИСКА%20С%20ПРОТОКОЛА%2014082023.PDF" target="_blank">ВЫПИСКА С ПРОТОКОЛА 14-08-2023</a> <br>
      <a href="/docs/итоги%20собрания%20ВОСА-14_08_2023.pdf" target="_blank">Итоги собрания ВОСА 14.08.2023</a> <br>
      <a href="/docs/Отчет%20об%20итогах%20голосоваия%2014082023.PDF" target="_blank">Отчет об итогах голосования 14.08.2023</a> <br>
      <a href="/docs/Протокол_об_итогах_голосования_ВОСА_14082023.PDF" target="_blank">Протокол об итогах голосования ВОСА 14.08.2023</a>
    `,
  },
  {
    title: 'Статистика посещений сайта',
    text: '',
  },
  {
    title: 'Проспекты эмиссий',
    text: `<!--<a href="/docs/akcii.PDF" target="_blank">Решение о выпуcке акций АО SOLUTIONS LAB</a>-->`,
  },
  {
    title: 'Безопасность',
    text: '',
  },
];