<template>
  <div
      id="app"
      :style="{ ...getViewMode, overflow: 'visible', fontSize: `${getFontSize}px !important`, zoom: `${getZoom}` }">
    <component :is="layout"></component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['getViewMode', 'getZoom', 'getFontSize']),
    layout() {
      const layoutName = this.$route.meta.layout || 'default';
      return () => import(`../src/layouts/${layoutName}.vue`);
    },
  },
};
</script>

<style lang="scss">
@import "assets/styles/scss/main";
</style>