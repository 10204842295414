import corporateGovernance from './corporate-governance';
import vacancies from './vacancies';

export default {
  empty: 'Пусто',
  site_links: {
    about_us: 'О нас',
    certificates: 'Сертификаты',
    partners: 'Партнеры',
    vacancies: 'Вакансии',
    'corporate-management': 'Корпоративное управление'
  },
  header: {
    title: 'Инновации, кадры, креатив - основы нашего успеха!',
    subtitle: 'Технологии открывают новые возможности, а мы открываем этот мир IT-технологий именно для Вас! Это — наша миссия. Это — наша жизнь.',
  },
  about_us: {
    title: 'О нас',
    text: 'Компания Solutions Lab зарекомендовала себя как опытный ИТ-интегратор и надежный партнер в области финтех и электронной коммерции. ' +
      'Предоставляя качественные услуги и решения, мы способствуем развитию и укреплению экономики и государства в целом. ' +
      'Быть лучше, чем вчера и развивать бизнес наших заказчиков — таков наш подход к работе. ' +
      'Краеугольный камень в нашей стратегии - это достойная оценка кадрового потенциала и постоянное совершенствование применяемых технологий.',
  },
  projects: {
    title: 'Проекты',
    installment_service: {
      title: 'Cистема рассрочки',
      subtitle: 'Онлайн-система, предоставляющая своим пользователям возможность приобретать товары и услуги на условиях рассрочки.',
    },
    marketplace: {
      title: 'Маркетплейс товаров',
      subtitle: 'Онлайн-агрегатор и маркетплейс товаров широчайщего профиля с оперативной доставкой и удобной рассрочкой.'
    },
    payment_system: {
      title: 'Платежная система',
      subtitle: 'Новое слово на рынке платежных инструментов с заманчивой системой лояльности и незабываемым клиентским опытом.'
    }
  },
  partners: {
    title: 'Партнеры',
  },
  certificates: {
    title: 'Сертификаты',
    halyal: {
      title: 'Халяльный бизнес',
      text: 'Онлайн-система, предоставляющая своим пользователям возможность приобретать товары и услуги на условиях рассрочки.',
    },
    company: {
      title: 'Solutions Lab',
      text: 'В соответствии с лицензией, выданной Центральным Банком Республики Узбекистан, Solutions Lab имеет право осуществлять деятельность платежной системы.',
    },
    it_park: {
      title: 'IT Park',
      text: 'Solutions Lab является резидентом IT Park Uzbekistan и пользуется всеми преимуществами данного членства.',
    },
    international_identification: {
      title: 'Международная идентификация',
      text: 'Настоящим ГП "Центральный депозитарий ценных бумаг" свидетельствует присвоение кодов ISIN, CFI, FISN в соответствии с международными стандартами ISO 6166, ISO 10962, ISO 18774.'
    }
  },
  career: {
    title: 'Карьера в Solution Lab',
    text: 'Наша команда состоит из людей, преданных своему делу, со своими разнообразными талантами и мы хотим, чтобы нас стало больше. ' +
      'Мы приглашаем сотрудников, имеющих профессиональные амбиции и неиссякаемую энергию, готовых развиваться вместе с компанией. ' +
      'Если ваша цель не просто найти работу, а построить блестящую карьеру, то вам к нам - в Solutions Lab! ' +
      'Откройте для себя целый мир новых возможностей вместе с нами.',
  },
  contacts: {
    title: 'Контакты'
  },
  homepage: 'Главная',
  corporate_governance: {
    title: 'Корпоративное управление',
    headerTitle: 'Информация о компании',
    list: corporateGovernance,
  },
  vacancies: {
    title: 'Вакансии',
    content_title: 'Разработка',
    list: vacancies,
    conditions:
      '• 5/2; 9.00•18.00; \n' +
      '• Официальное трудоустройство; \n' +
      '• Достойная оплата; \n' +
      '• Участие в крупных и уникальных проектах большой развивающейся компании; \n' +
      '• Офис в центре города; \n',
  },
  vacancy: {
    salary: `до {value} сум`,
    experience: {
      title: 'Требуемый опыт работы:',
      text: 'от 2 лет. (Стажеров и соискателей с опытом ниже 2-х лет не рассматриваем).'
    },
    type_job: {
      title: 'Тип занятости:',
      text: 'Полная занятость, полный день'
    },
    location: {
      title: 'Место работы:',
      text: 'Ташкент'
    },
    responsibilities: 'Обязанности:',
    requirements: 'Требования:',
    offer: 'Условия работы:',
  },
  footer: {
    address: 'Узбекистан, г. Ташкент, пр. Мустакиллик, проезд 1, дом 8 (Ориентир: Метро Хамида Алимджана, стоматологическая клиника SADAFKOM, школа танцев Sense)',
    site_copyright: '© 2022 "SOLUTIONS LAB" Все права защищены',
    visits: 'Посещений за день:',
    active_users: 'Активно сейчас:',
  },
  bottom_navbar: {
    text: '© 2022, АО «Solutionslab», официальный сайт. При копировании материалов ссылка на сайт обязательна.'
  },
  button: {
    contact: 'Cвязаться',
    more: 'Подробнее',
    view_all: 'Смотреть все',
  },
  input: {
    search: {
      placeholder: 'Найти',
    }
  },
  phone: 'Тел: {number}',
  zoom: 'Масштаб: {value} %',
  font_size: 'Размер шрифта: {value} %',
  view: 'Вид',
  submit_order: 'Оставить заявку',
  tell_about_yourself: 'Расскажите о себе'
};