export const CALL_CENTER_PHONE_NUMBER = '+998 (78) 777-15-15';

export const EMAIL = 'solutionslab@gmail.com'

export const NAV_LINKS = [
  'about_us',
  'certificates',
  'partners',
];

export const VIEW_MODE = {
  'simple': {},
  'gray': { filter: 'grayscale(1)' },
  'black': { filter: 'grayscale(1) invert(1)', color: '#636363' }
}

/*
*  todo: Please add salary after pushing vacancies.
*   Else may be errors with vacancy id (See mixin Vacancies)
*  @source @/mixins/vacancies.mixin.js
*
* */
export const SALARY = {
  java: Intl.NumberFormat().format(50_000_000),
  ios: Intl.NumberFormat().format(50_000_000),
  php: Intl.NumberFormat().format(50_000_000),
  vue: Intl.NumberFormat().format(50_000_000),
}