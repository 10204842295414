import corporateGovernance from './corporate-governance';
import vacancies from './vacancies';

export default {
  empty: 'Empty',
  site_links: {
    about_us: 'About us',
    certificates: 'Certificates',
    partners: 'Partners',
    vacancies: 'Vacancies',
    'corporate-management': 'Corporate governance',
  },
  header: {
    title: 'Innovations, team, creativity - the drivers of our success!',
    subtitle: 'Technologies open new opportunities, we open this world of IT for you! This is our mission.',
  },
  about_us: {
    title: 'About us',
    text: 'Solutions Lab has established itself as an experienced IT integrator and a reliable partner in the field of fintech and e-commerce. By providing high-quality services and solutions, we contribute to the development and strengthening of the economy and the state as a whole. Our approach to work is to be better than yesterday and to develop the business of our customers. The cornerstone of our strategy is a decent assessment of human resources potential and continuous improvement of the applied technologies.',
  },
  projects: {
    title: 'Projects',
    installment_service: {
      title: 'Installments service',
      subtitle: 'An online system that provides its users with the opportunity to purchase goods and services on installment terms.',
    },
    marketplace: {
      title: 'Marketplace of goods',
      subtitle: 'An online aggregator and marketplace of a wide range of goods with prompt delivery and convenient installments plan.',
    },
    payment_system: {
      title: 'Payment system',
      subtitle: 'A new word in the market of payment instruments with an attractive loyalty system and an unforgettable customer experience.',
    },
  },
  partners: {
    title: 'Partners',
  },
  certificates: {
    title: 'Certificates',
    halyal: {
      title: 'Halal business',
      text: 'The Muslim Board of the Republic of Uzbekistan confirmed the compliance of the practices of the Paymart installment system with the norms and laws of Sharia.',
    },
    company: {
      title: 'Solutions Lab',
      text: 'In accordance with the license issued by the Central Bank of the Republic of Uzbekistan, Solutions Lab has the right to carry out the activities of the payment system.',
    },
    it_park: {
      title: 'IT Park',
      text: 'Solutions Lab is a resident of IT Park Uzbekistan and enjoys all the benefits of this membership.',
    },
    international_identification: {
      title: 'International identification',
      text: 'Hereby SE "Central Depository of Securities" testifies to the assignment of ISIN, CFI, FISN codes in accordance with the international standards ISO 6166, ISO 10962, ISO 18774.'
    }
  },
  career: {
    title: 'Career at Solutions Lab',
    text: 'Our team consists of people who are dedicated to their work, with their diverse talents, and we want to see more such people in our team. We invite employees who have professional ambitions and inexhaustible energy, who are ready to develop together with the company. If your goal is not just to find a job, but to build a brilliant career, then you are welcome here - at Solutions Lab! Discover a whole new world of possibilities with us.',
  },
  contacts: {
    title: 'Contacts'
  },
  homepage: 'Home',
  corporate_governance: {
    title: 'Corporate governance',
    headerTitle: 'Company information',
    list: corporateGovernance,
  },
  vacancies: {
    title: 'Careers',
    content_title: 'Development',
    list: vacancies,
    conditions:
      '• 5/2, 9.00-18.00;\n' +
      '• Official employment;\n' +
      '• Decent pay;\n' +
      '• Participation in large and unique projects of a large growing company;\n' +
      '• Office in the city center.'
  },
  vacancy: {
    salary: `Up to {value} UZS`,
    experience: {
      title: 'Required work experience:',
      text: '2+ years (Interns and applicants with less than 2 years of experience are not considered).'
    },
    type_job: {
      title: 'Job type:',
      text: 'Full-time'
    },
    location: {
      title: 'Location:',
      text: 'Tashkent'
    },
    responsibilities: 'Responsibilities:',
    requirements: 'Requirements:',
    offer: 'What we offer:',
  },
  footer: {
    address: 'Uzbekistan, Tashkent city, Mustaqillik Ave., 1st passage, building 8 (Landmark: Hamid Alimjan metro station, SADAFKOM dental clinic, Sense dance school)',
    site_copyright: '© 2022 "SOLUTIONS LAB" All rights reserved.',
    visits: 'Visits per day:',
    active_users: 'Active now:',
  },
  bottom_navbar: {
    text: '© 2022, JSC «Solutionslab», officiall site. When copying materials, a link to the site is required.'
  },
  button: {
    contact: 'Contact',
    more: 'More',
    view_all: 'View all'
  },
  input: {
    search: {
      placeholder: 'Search',
    }
  },
  phone: 'Tel: {number}',
  zoom: 'Zoom: {value} %',
  font_size: 'Font size: {value} %',
  view: 'View',
  submit_order: 'Submit your application',
  tell_about_yourself: 'Tell us about yourself'
};