import corporateGovernance from './corporate-governance';
import vacancies from './vacancies';

export default {
  empty: 'Bo\'sh',
  site_links: {
    about_us: 'Biz haqimizda',
    certificates: 'Sertifikarlarimiz',
    partners: 'Hamkorlarimiz',
    vacancies: 'Ish o\'rinlari',
    'corporate-management': 'Korporativ boshqaruv',
  },
  header: {
    title: 'Innovatsiyalar, kadrlar, kreativlik - muvaffaqiyatimiz poydevori!',
    subtitle: 'Texnologiyalar yangi imkoniyatlarni ochadi va biz ushbu IT texnologiyalar olamini siz uchun ochamiz! Bu bizning missiyamiz.',
  },
  about_us: {
    title: 'Biz haqimizda',
    text: 'Solutions Lab o‘zini moliyaviy texnologiylar va elektron tijorat sohasida tajribali IT integratori va ishonchli hamkor sifatida ko‘rsatgan. Yuqori sifatli xizmatlar va yechimlarni taqdim etish orqali biz iqtisodiyotni va umuman davlatni rivojlantirish va mustahkamlashga hissa qo\'shib kelmoqdamiz. Kechagidan yaxshiroq bo\'lish va mijozlarimizning biznesini rivojlantirish - shu bizning ishga yondashuvimiz. Strategiyamizning asosi inson resurslarini munosib baholash va qo\'llaniladigan texnologiyalarni doimiy ravishda takomillashtirishdir.',
  },
  projects: {
    title: 'Loyihalarimiz',
    installment_service: {
      title: 'Muddatli to’lov xizmati',
      subtitle: 'O\'z foydalanuvchilariga tovar va xizmatlarni muddatli to’lov asosida sotib olish imkoniyatini beruvchi onlayn tizim.',
    },
    marketplace: {
      title: 'Mahsulotlar marketpleysi',
      subtitle: 'Tez yetkazib berish va qulay muddatli to’lov imkoniyati mavjud onlayn agregatori va eng keng profildagi tovarlarning marketpleysi.',
    },
    payment_system: {
      title: 'To\'lov tizimi.',
      subtitle: 'Jozibador sodiqlik tizimi va unutilmas mijozlar tajribasiga ega to\'lov vositalari bozoridagi yangi so\'z.',
    },
  },
  partners: {
    title: 'Hamkorlarimiz',
  },
  certificates: {
    title: 'Sertifikatlar',
    halyal: {
      title: 'Halol biznes',
      text: 'O‘zbekiston Respublikasi Musulmonlar Idorasi Paymart muddatli to‘lov tizimi amaliyoti shariat me’yorlari va qonunlariga muvofiqligini tasdiqlagan.',
    },
    company: {
      title: 'Solutions Lab',
      text: 'O‘zbekiston Respublikasi Markaziy banki tomonidan berilgan litsenziyaga muvofiq, Solutions Lab to‘lov tizimi faoliyatini amalga oshirish huquqiga ega.',
    },
    it_park: {
      title: 'IT Park',
      text: 'Solutions Lab IT Park Uzbekistan rezidenti hisoblanadi va ushbu aʼzolikning barcha afzalliklaridan foydalanadi.',
    },
    international_identification: {
      title: 'Xalqaro identifikatsiya',
      text: 'Shu bilan “Qimmatli qog‘ozlar markaziy depozitariysi” DK ISO 6166, ISO 10962, ISO 18774 xalqaro standartlariga muvofiq ISIN, CFI, FISN kodlari berilganligiga dalolat beradi.'
    }
  },
  career: {
    title: 'Solutions Lab bilan karyera',
    text: 'Bizning jamoamiz o‘z ishiga fidoyi, o‘zining turli hil iste’dodiga ega odamlardan tashkil topgan va bizni jamoamizda ko’proq shunday insonlsrni ko‘rishni istaymiz. Biz kompaniya bilan birgalikda rivojlanishga tayyor bo\'lgan, professional ambitsiyaga va bitmas-tuganmas energiyaga ega xodimlarni taklif qilamiz. Agar sizning maqsadingiz shunchaki ish topish emas, balki yorqin karyera qurish bo\'lsa, unda siz Solutions Lab-ga xush kelibsiz! Biz bilan yangi imkoniyatlar dunyosini kashf eting.',
  },
  contacts: {
    title: 'Aloqa',
  },
  homepage: 'Bosh sahifa',
  corporate_governance: {
    title: 'Korporativ boshqaruv',
    headerTitle: 'Kompaniya haqida ma\'lumot',
    list: corporateGovernance,
  },
  vacancies: {
    title: 'Ish o’rinlari',
    content_title: 'Dasturlash',
    list:vacancies,
    conditions:
      '• 5/2, 9.00-18.00;\n' +
      '• Rasmiy ishga joylashish;\n' +
      '• Munosib ish haqi;\n' +
      '• O\'sib borayotgan katta kompaniyaning yirik va noyob loyihalarida ishtirok etish;\n' +
      '• Shahar markazidagi ofis;\n',
  },
  vacancy: {
    salary: `{value} so\'mgacha`,
    experience: {
      title: 'Kerakli ish tajribasi:',
      text: '2+ yil (Amaliyotchilar va 2 yildan kam ish tajribasiga ega nomzodlar ko’rib chiqilmaydi).'
    },
    type_job: {
      title: 'Bandlik turi:',
      text: 'To\'liq kun'
    },
    location: {
      title: 'Ish joyi:',
      text: 'Toshkent'
    },
    responsibilities: 'Mas\'uliyatlar:',
    requirements: 'Talablar:',
    offer: 'Ish sharoitlari:',
  },
  footer: {
    address: 'O\'zbekiston, Toshkent sh., Mirzo Ulug\'bek tumani, Mustaqillik shoh ko\'chasi, 1-tor kocha, 8-uy (Mo\'ljal: Hamid Olimjon metro bekati, SADAFKOM stomatologiya klinikasi, Sense raqs maktabi)',
    site_copyright: '© 2022 "SOLUTIONS LAB" Barcha huquqlar himoyalangan.',
    visits: 'Kuniga tashriflar:',
    active_users: 'Hozir faol:',
  },
  bottom_navbar: {
    text: '© 2022, AJ «Solutionslab», rasmiy web-sayt. Materiallardan nusxa koʻchirishda saytga havola ko\'rsatish muhim.',
  },
  button: {
    contact: 'Bog\'lanish',
    more: 'Ko\'proq',
    view_all: 'Barchasini ko\'rish',
  },
  input: {
    search: {
      placeholder: 'Qidirish.',
    }
  },
  phone: 'Tel: {number}',
  zoom: 'Masshtab: {value} %',
  font_size: 'Shrift hajmi: {value} %',
  view: 'Ko\'rinish',
  submit_order: 'Ariza qoldirish',
  tell_about_yourself: 'O’zingiz haqingizda aytib bering'
};