import vacancies from './vacancies';
import slugify from 'slugify';
import { SALARY } from '../../utils/constants';

export default [
  {
    title: 'Company documents',
    text: `
      <a href="/docs/Уста%20АО%20SL%2022052023%20адрес%20май.pdf" target="_blank">Charter of AS SOLUTIONS LAB in the new edition" May 2023</a> <br>
      <a href="/docs/ustav.pdf" target="_blank">Company charter</a><br>
      <a href="/docs/устав%20АО%20SL%2020022023.pdf" target="_blank">Company charter 20-02-2023</a><br>
      <a href="/docs/гувохнома%20АО%20SL.PDF" target="_blank">Certificate of state registration</a> <br>
      <a href="/docs/Положение%20Исп%20орган%20АО.PDF" target="_blank">Regulations on the executive body</a><br>
      <a href="/docs/Положение%20о%20НС%20АО.PDF" target="_blank">Regulations on the Supervisory Board</a><br>
      <a href="/docs/Положение%20ОСА%20АО%20SL.PDF" target="_blank">Regulations on the General Meeting of Shareholders</a><br>
      <a href="/docs/Кодекс_корпоратвиного_управления_АО.PDF" target="_blank">JSC Corporate Governance Code</a><br>
      <a href="/docs/Регламент%20провдедения%20ВОСА.PDF" target="_blank">Conducting regulations Extraordinary General Meeting of Shareholders</a> <br>
      <a href="/docs/гувохнома%20АО%20SL.PDF" target="_blank">SOLUTIONS LAB SA registration certificate 24-05-2023</a> <br>
      <a href="/docs/Устав%20SL%2030_06_2023%20ГОСА.pdf" target="_blank">Charter of JSC SOLUTIONS LAB following the results of the EGMSh on 30-06-2023</a>
    `,
  },
  {
    title: 'Reports',
    text: `
      <a href="/docs/ГОДОВОЙ_ОТЧЕТ_эмитента_по_итогам_2022_НСБУ.pdf" target="_blank">Annual report of the IFRS issuer following the results of AGM 2023</a> <br>
      <a href="/docs/ГОДОВОЙ_ОТЧЕТ_эмитента_по_итогам_2022_МСФО.pdf" target="_blank">Annual report of the NAS issuer following the results of the EGMSh 30-06-2023</a> <br>
      <h4>Reports - 2023</h4>
      <h6>Quarterly report</h6>
      <a href="/docs/квартальный_отчет_по_итогам_1_кварт_АО_Solutions_Lab.pdf" target="_blank">Download</a> <br>
      <a href="/docs/кварт%20отчет%20по%20итогам%206%20мес%20АО%20SL.PDF" target="_blank">Quarterly report on the results of the 2nd quarter (half year)" 07/28/2023</a> <br>
      <a href="/docs/Объявление_о_преимущественном_праве.pdf" target="_blank">Announcement of pre-emptive right</a>
    `,
  },
  {
    title: 'Information about significant facts',
    text: `
      <a href="/docs/facts.pdf" target="_blank">Significant fact № 25 (16.11.2022)</a><br>
      <a href="/docs/информация_о_покупке_20_процентов_акций.pdf" target="_blank">Information about the purchase of 20 percent of the shares</a> <br>
      <a href="/docs/информация_о_покупке_35_процентов_акций.pdf" target="_blank">Information about the purchase of 35 percent of the shares</a> <br><br>
      <h4>SIGNIFICANT FACTS 2023 YEAR</h4>
      <a href="/docs/существенный%20факт%2035.pdf" target="_blank">Significant fact № 35</a> <br>
      <a href="/docs/существенный%20факт%2036.pdf" target="_blank">Significant fact № 36</a> <br>
      <a href="/docs/итоги%20собрания%20ВОСА-20-02-2023.pdf" target="_blank">Results of the EGMSh meeting</a><br>
      <a href="/docs/сущ%20факт%2036%20афф%20лица%20TFT%20вхождение.pdf" target="_blank">Significant fact 36 dated 30-03-2023</a> <br>
      <a href="/docs/сущ%20факт%2002%20адрес.pdf" target="_blank">Significant fact 02</a> <br>
      <a href="/docs/сущ%20факт%2006%20по%20итогам%202022.pdf" target="_blank">Significant fact 06 following the results of the EGMSh</a> <br>
      <a href="/docs/сущ%20факт%2032%20по%20итогам%202022.pdf" target="_blank">Significant fact 32 following the results of the EGMSh</a> <br>
      <a href="/docs/сущ%20факт%2006%20выпуск%20акций.pdf" target="_blank">Significant fact 06 share issue</a> <br>
      <a href="/docs/сущ%20факт%2006%20по%20итогам%20ВОСА%2014082023.pdf" target="_blank">Significant fact 06 following the results of the EGM 14-08-2023</a> <br>
      <a href="/docs/сущ%20факт%2008%20изменение%20НС%2014082023.pdf" target="_blank">Significant fact 06 изменения НС 14-08-2023</a> <br>
      <a href="/docs/сущ_факт_36_афф_лица_наб_совет_смена_14082023.pdf" target="_blank">Significant fact 06 change affilates 14-08-2023</a> <br>
      <a href="/docs/сущ%20факт%2006%20выпуск%20акций%2025082023.pdf" target="_blank">Significant fact 06 25.08.2023</a> <br>
      <a href="/docs/сущ%20факт%2025%20от%2021.09.2023%20выпуск%20акций.pdf" target="_blank">Significant fact 25 21.09.2023</a>
    `,
  },
  {
    title: 'Listing',
    text: `
      <a href="/docs/сущ%20факт%20листинг%2024112022%20пдф.pdf" target="_blank">Significant listing fact</a> <br>
      <a href="/docs/ПРЕДЛОЖЕНИЕ_О_ПРОДАЖЕ_АКЦИЙ_Солюшн_Лаб.pdf" target="_blank">OFFER FOR SALE OF SHARES JSC "Solutions Lab"</a>
    `
  },
  {
    title: 'Information about open competitions (tenders) and auctions held by the company',
    text: '',
  },
  {
    title: 'Up-to-date information on the main indicators of the financial and economic condition of the company',
    text: '',
  },
  {
    title: 'Information about affiliates',
    text: `
      <a href="/docs/Перечень_аффилированных_лиц_16012023.pdf" target="_blank">List of affiliates 16.01.2023</a> <br>
      <a href="/docs/Перечень_аффилированных_лиц_17102022.pdf" target="_blank">List of affiliates 17.10.2022</a>
    `,
  },
  {
    title: 'Governance structure',
    text: `
      <a href="/docs/структура%20управления%20АО%20SOLUTIONS%20LAB.PDF" target="_blank">Governance structure JSC SOLUTIONS LAB</a>
    `,
  },
  {
    title: 'Information about dividends',
    text: '',
  },
  {
    title: 'Information about directors',
    text: `
      <a href="/docs/Информация%20о%20руководителях%20в%20АО.pdf" target="_blank">Information about the leaders in JSC</a>
    `,
  },
  {
    title: 'Business plan',
    text: '',
  },
  {
    title: 'Auditor\'s reports',
    text: `
      <a href="/docs/Аудит_Соллюшен_2022г%20МСФО.pdf" target="_blank">Audit report IFRS for 2022</a>
    `,
  },
  {
    title: 'Jobs',
    text: vacancies.map((vacancy, index) => {
      return `
        <a href="/vacancies/${slugify(Object.keys(SALARY)[index])}">${vacancy.name}</a> <br>
      `
    }).join(''),
  },
  {
    title: 'General Meeting of Shareholders',
    text: `
      <a href="/docs/Объявление_о_проведении_Внеочередного_общего_собрания_акционеров.pdf" target="_blank">Announcement about the extraordinary general meeting of shareholders</a> <br>
      <a href="/docs/Объявление_о_проведении_Внеочередного_общего_собрания_акционеров%2031-01-2023.pdf" target="_blank">Announcement about the extraordinary general meeting of shareholders 31-01-2023</a> <br>
      <a href="/docs/Уведомление%20Акционерам%2012.12.2022.pdf" target="_blank">Notice to Shareholders</a> <br>
      <a href="/docs/Уведомление%20Акционерам%2030.01.2023.pdf" target="_blank">Notice to Shareholders 31-01-2023</a> <br>
      <a href="/docs/уведомление%20о%20переносе%20воса.pdf" target="_blank">Notice of adjournment extraordinary general meeting</a> <br>
      <a href="/docs/уведомление%20о%20переносе%20воса%2020-02-2023.pdf" target="_blank">Notice of adjournment of the extraordinary general meeting to 20-02-2023</a> <br>
      <a href="/docs/Отчет_по_итогам_голосования_ВОСА_20022023.PDF" target="_blank">Report on voting results of the Extraordinary General Meeting of Shareholders 20-02-2023</a> <br>
      <a href="/docs/протокол%20ВОСА%2020022023.PDF" target="_blank">Protocol of the Extraordinary General Meeting of Shareholders 20-02-2023</a> <br>
      <a href="/docs/Уведомление_Акционерам_ВОСА_22052023.pdf" target="_blank">Notice of Extraordinary Meeting of Shareholders 22-05-2023</a> <br>
      <a href="/docs/Протокол%20ВОСА%20АО%20SL%2022052023%20адрес.PDF" target="_blank">Protocol of the Extraordinary General Meeting of Shareholders 20-02-2023</a> <br>
      <a href="/docs/отчет_об_итогах_голосования_22052023_адрес.PDF" target="_blank">Voting results report 22-05-2023</a> <br>
      <a href="/docs/Уведомление_Акционерам_ГОСА_по_итогам_2022.pdf" target="_blank">Notification to shareholders about the EGM for 2022</a> <br>
      <a href="/docs/Отчет_об_итогах_голосования_ГОСА_30_06_2023.PDF" target="_blank">Voting results report 30-06-2023</a><br>
      <a href="/docs/уведомление%2014%2008%202023%20НС.pdf" target="_blank">Notification of the EGM on 14-08-2023</a> <br>
      <a href="/docs/ВЫПИСКА%20С%20ПРОТОКОЛА%2014082023.PDF" target="_blank">EXTRACT FROM THE PROTOCOL 14-08-2023</a> <br>
      <a href="/docs/итоги%20собрания%20ВОСА-14_08_2023.pdf" target="_blank">EGM meeting results 14.08.2023</a> <br>
      <a href="/docs/Отчет%20об%20итогах%20голосоваия%2014082023.PDF" target="_blank">Voting results report 14.08.2023</a> <br>
      <a href="/docs/Протокол_об_итогах_голосования_ВОСА_14082023.PDF" target="_blank">Protocol on voting results EGM 14.08.2023</a>
    `,
  },
  {
    title: 'Site visit statistics',
    text: '',
  },
  {
    title: 'Issue prospectuses',
    text: `<!--<a href="/docs/akcii.PDF" target="_blank">Decision to issue shares of the JSC SOLUTIONS LAB</a>-->`,
  },
  {
    title: 'Safety',
    text: '',
  },
];