export default [
  // {
  //   name: 'Android разработчик',
  //   level: 'middle/senior',
  //   must:
  //     '• Разработка приложений под Android;\n' +
  //     '• Разрабатывать ПО в соответствии с ТЗ;\n' +
  //     '• Исправлять обнаруженные дефекты(баги);\n' +
  //     '• Реализация нестандартных элементов UI.',
  //   requirements:
  //     '• Знание Android SDK (API level 16+);\n' +
  //     '• Знание Java / Kotlin;\n' +
  //     '• Понимание RESTful API и HTTP;\n' +
  //     '• Опыт работы с системами контроля версий (Git);\n' +
  //     '• Знание шаблонов проектирования, принципов SOLID, умение их применять.',
  //   conditions:
  //     '• 9.00 •18.00, 5/2;\n' +
  //     '• Официально трудоустройство;\n' +
  //     '• Зарплата по итогам собеседования•достойная;\n' +
  //     '• Территориально м. Минор.',
  // },
  {
    name: 'Java разработчик',
    level: 'Middle/Senior',
    experience: '',
    must: 'Разработка и развитие продуктов компании.',
    requirements:
      '• Знание Java Spring, Spring Boot;\n' +
      '• Понимание RESTful API;\n' +
      '• Опыт работы с реляционными СУБД ( PostgreSQL);\n' +
      '• Разработка систем с микро сервисной архитектурой;\n' +
      '• Опыт в разработке высоконагруженных приложений;\n' +
      '• Опыт работы с Docker или другими контейнерными решениями;\n' +
      '• Опыт работы с RabbitMQ или Apache Kafka.',
  },
  {
    name: 'IOS разработчик',
    level: 'Middle/Senior',
    experience: '',
    must:
      '• Сопровождение и развитие текущих проектов;\n' +
      '• Разработка новых \n flutter(Android/iOS) проектов компании.',
    requirements:
      '• Разрабатывать на Swift;\n' +
      '• Разрабатывать ПО в соответствии с ТЗ;\n' +
      '• Исправлять обнаруженные дефекты(баги);\n' +
      '• Реализация нестандартных элементов UI.',
  },
  {
    name: 'PHP разработчик',
    level: 'Middle/Senior',
    experience: '',
    must:
      '• Разработка веб приложений на PHP + MySQL / PostgreSQL;\n' +
      '• Реализация сервисной логики;\n' +
      '• Создание функционала сайта;\n' +
      '• Создание API.',
    requirements:
      '• Oпыт работы с фреймворком Laravel(PHP);\n' +
      '• Опыт написания REST API, \n взаимодействия со сторонними API;\n' +
      '• Умение писать SQL запросы и работа с MySQL, \n PostgreSQL, Redis;\n' +
      '• Знаниe принципов OOP, SOLID;\n' +
      '• Опыт работы с Git.',
  },
  {
    name: 'Frontend разработчик',
    level: 'Middle/Senior',
    experience: '',
    must:
      '• Разработка Frontend•приложения на Vue.js;\n' +
      '• Поддержка и доработка существующих продуктов;\n' +
      '• Знание асинхронного взаимодействия \n (promises, async/await);\n' +
      '• Знание модульности javascript/typescript;\n' +
      '• Иметь навыки кросс•браузерной \n верстки, понимать как работает рендеринг;\n' +
      '• Уверенные знания html и css (less, sass).\n',
    requirements:
      '• Знание Vue.js, HTML/CSS, JavaScript;\n' +
      '• Умение работать с Git и Github/Gitlab;\n' +
      '• Понимание принципов проектирования REST API.',
  },
];
